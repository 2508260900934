import React, { useContext, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Link from "next/link";

// Assets
import CARID from "../public/data/cars.json";

// Context maintains search settings across pages
import { SearchContext } from "./SearchProvider";

export default function SearchTool(props) {
  const { make, setMake } = useContext(SearchContext);
  const { model, setModel } = useContext(SearchContext);

  function vehicleMakeSelector() {
    // Once make specified, return selector list
    return (
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="select-make">Make*</InputLabel>
        <Select
          labelId="select-make"
          data-testid="select-make"
          id="s-select-make"
          value={make}
          label="Make"
          onChange={(event) => {
            setMake(event.target.value);
            setModel("");
          }}
        >
          {vehicleMakeSelectorList(Object.keys(CARID))}
        </Select>
      </FormControl>
    );
  }

  function vehicleMakeSelectorList(vehicleMakeList) {
    return vehicleMakeList.map((vehicleMake) => (
      <MenuItem key={vehicleMake} value={vehicleMake}>
        {vehicleMake}
      </MenuItem>
    ));
  }

  function vehicleModelSelector(make) {
    //Don't display model selector until make specified
    if (!make)
      return (
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          {
            <Button disabled variant="outlined">
              Model
            </Button>
          }
        </FormControl>
      );

    // Once make specified, return selector list
    return (
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="select-model">Model*</InputLabel>
        <Select
          labelId="select-model"
          id="s-select-model"
          value={model}
          label="Model *"
          onChange={(event) => {
            setModel(event.target.value);
          }}
        >
          {vehicleModelSelectorList(CARID[make])}
        </Select>
      </FormControl>
    );
  }

  function vehicleModelSelectorList(vehicleModelList) {
    // Gate to check if model has been selected yet
    if (!vehicleModelList) return null;

    return Object.keys(vehicleModelList).map((vehicleModel) => (
      <MenuItem key={vehicleModel} value={vehicleModel}>
        {vehicleModel}
      </MenuItem>
    ));
  }

  function partsSearchButton(make, model) {
    // Gate to check if model has been selected yet
    if (!(make && model)) return null;

    let searchUrl = "/search/" + make + "/" + model;
    // console.log(searchUrl);
    return (
      <Link style={{ textDecoration: "none", color: "unset" }} href={searchUrl} prefetch={false}>
        <Button variant="contained" color="success">
          <Typography>View Parts</Typography>
        </Button>
      </Link>
    );
  }

  return (
    <div className="SearchTool">
      <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center">
        <Grid item xs="auto">
          {vehicleMakeSelector()}
        </Grid>
        <Grid item xs="auto">
          {vehicleModelSelector(make)}
        </Grid>
        <Grid item xs="auto">
          {partsSearchButton(make, model)}
        </Grid>
      </Grid>
    </div>
  );
}
